import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { Link, graphql } from "gatsby"
import { Container } from "reactstrap"

import Header from "../components/header"
import Footer from "../components/footer"
import { yellow, black } from "../utils/colors"

const tagsContainer = css({
  margin: "0 auto",
  lineHeight: "2em",
  "& a, a:hover": {
      color: black
  },
  "& a:hover": {
      backgroundColor: yellow
  },
  "& h1": {
      fontSize: "2rem",
      margin: "2rem 0 1rem",
      lineHeight: "1.5em"
  },
  "& h2": {
      fontSize: "1.7rem",
      fontWeight: "bold",
      margin: "4rem 0 1.5rem"
  },
  "& h3": {
      fontSize: "1.3rem",
      fontWeight: "normal",
      borderBottom: "solid 1px " + yellow,
      margin: "2rem 0 1.5rem",
      padding: "0.8rem 0",
  },
  "& hr": {
      margin: "4rem 0 4rem"
  },
  "& header": {
      padding: "42px 0",
      "& .date": {
          fontSize: 14,
          fontFamily: "'Halant', serif",
          margin: "0 0.5rem"
      },
      "& .category": {
          fontSize: 14,
          fontFamily: "'Halant', serif",
          margin: "0 0.5rem"
      },
  },
  "& .tags-body": {
      padding: "0 0 56px",
      textAlign: "center",
      minHeight: "40vh"
  },
  "& ul": {
    fontSize: 12,
    display: "block",
    margin: "0.5rem -4px",
    "& a, a:hover": {
        backgroundColor: "rgba(0,0,0,0)"
    },
    "& li": {
        display: "inline-block",
        backgroundColor: yellow,
        margin: "0 4px",
        padding: "0 8px",
        listStyle: "none",
    }
  }
})

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    <div>
      <Header/>
      <Container css={tagsContainer}>
        <header className="text-center">
          <h1 className="halant">{tagHeader}</h1>
        </header>
        <div className="tags-body">
          <ul>
            {edges.map(({ node }) => {
              const { slug } = node.fields
              const { title } = node.frontmatter
              return (
                <li key={slug}>
                  <Link to={slug}>{title}</Link>
                </li>
              )
            })}
          </ul>
        <Link to="/blog/tags">All tags</Link>
        </div>
      </Container>
      <Footer/>
    </div>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
